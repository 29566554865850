import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FieldError, useForm } from "react-hook-form";
import * as yup from "yup";
import Layout from "../components/Layout";
import TagManager from "../components/TagManager";

type ContactFormInputs = {
    name: string;
    company?: string;
    email: string;
    message: string;
};

const schema = yup.object({
    name: yup.string().required().label("Your name"),
    email: yup.string().required().email().label("The email address"),
    message: yup.string().required("Please enter a message"),
}).required();

const Contact = () => {
    const { register, reset, handleSubmit, formState: { errors, isSubmitting } } = useForm<ContactFormInputs>({
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit((data) => {
        fetch("https://europe-west3-incritech.cloudfunctions.net/fn-contact", {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(() => {
            reset();
        });
    });

    const getFieldClass = (error: FieldError | undefined) => {
        if (!error) {
            return "form-control";
        }
        return "form-control is-invalid";
    }

    return (
        <Layout bodyClass="page-default-single">
            <TagManager />
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCXNGPD" height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
                </iframe>
            </noscript>
            <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
                <div className="row justify-content-start">
                    <div className="col-12 col-md-8">
                        <p className="title">Contact us</p>
                        <div className="content mt-4">
                            <form onSubmit={onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="inputName" className="needs-validation">Name</label>
                                    <input type="text" className={getFieldClass(errors.name)} id="inputName" aria-describedby="emailHelp" placeholder="John Doe" {...register("name")} />
                                    <p>{errors.name?.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputCompany">Company</label>
                                    <input type="text" className="form-control" id="inputCompany" aria-describedby="emailHelp" placeholder="Contoso" {...register("company")} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputEmail">Email address</label>
                                    <input type="email" className={getFieldClass(errors.email)} id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" {...register("email")} />
                                    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                    <p>{errors.email?.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                                    <textarea className={getFieldClass(errors.message)} id="exampleFormControlTextarea1" rows={3} {...register("message")}></textarea>
                                    <p>{errors.message?.message}</p>
                                </div>

                                <button className="button" type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;
